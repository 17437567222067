import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HOME_LOADING, HOME_LOADING_ERROR } from "../../../redux/actions/types";
import { Document, Page, pdfjs } from "react-pdf";
import "./PdfView.css";

export default function PdfView() {
  const location = useLocation();
  const dispatch = useDispatch();
  // const { pdfUrl } = location.state;

  const { userToken } = useSelector((state) => state.authState);
  const [fileurl, setFileurl] = useState("");
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    dispatch({
      type: HOME_LOADING,
    });
    var myHeaders = new Headers();
    myHeaders.append("If-Range", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(location.state?.pdfUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setFileurl(url);
        dispatch({
          type: HOME_LOADING_ERROR,
        });
      })
      .catch((error) => {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        console.log("error", error);
      });
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = async () => {
    dispatch({
      type: HOME_LOADING,
    });
    var myHeaders = new Headers();
    myHeaders.append("If-Range", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(location.state?.pdfUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "document.pdf";
        a.click();
        URL.revokeObjectURL(url);
        dispatch({
          type: HOME_LOADING_ERROR,
        });
      })
      .catch((error) => console.log("error", error));
  };

  return (
    // <>
    //   <div className="">
    //     <iframe
    //       src=""
    //       width="100%"
    //       height="100%"
    //       style={{ height: "100vh" }}
    //     ></iframe>
    //   </div>
    // </>

    <div>
      <button
        className="btn btn-primary d-flex "
        style={{ position: "absolute", right: 5 }}
        onClick={handleDownload}
      >
        <i class="fa-solid fa-download fs-3 text-dark"></i>
      </button>

      <div className="d-flex justify-content-center align-items-center my-2">
        <div className="" style={{}}>
          <div className="d-flex justify-content-center">
            <div className="d-flex align-items-center ">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (pageNumber == 1) {
                  } else {
                    setPageNumber((pageNumber) => pageNumber - 1);
                  }
                }}
              >
                {"<"}
              </button>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (pageNumber == numPages) {
                  } else {
                    setPageNumber((pageNumber) => pageNumber + 1);
                  }
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1, width: "100%", maxWidth: "800px" }}>
          <Document file={fileurl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={window.innerWidth} />
          </Document>
        </div>
      </div>
    </div>
  );
}
