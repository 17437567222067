import {
  LOG_OUT,
  HOME_LIST,
  HOME_LOADING,
  HOME_LOADING_ERROR,
  CATEGORY_DETAIL,
  GET_CARD_ITEMS,
  GET_LIKE_ITEMS,
  GET_LIKE_ITEMS_LOADING,
  GET_LIKE_ITEMS_ERROR,
  LIKE_UNLIKE_SERVER_SIDE_LOADING,
  LIKE_UNLIKE_SERVER_SIDE_ERROR_DONE,
  LIKE_ITEM_VALUE_ADD,
  UNLIKE_ITEM_VALUE_REMOVE,
  SET_CARD_QTY_AND_TOTAL,
  REMOVE_CART,
  REMOVE_WISHLIST,
  GET_WISHLIST_ERROR,
  GET_ORDER,
  PLACE_ORDER,
  SPARE_PARTS,
  SPARE_PARTS_DETAIL,
  GRID,
  APP_VERSION,
  GET_CUSTOMER,
  SELECTED_CUSTOMER_ID,
  GET_DISCOUNT,
  CUSTOMER_ADD,
  CUSTOMER_ADD_LOADING,
  CUSTOMER_ADD_ERROR,
  GET_CUSTOMER_ADD,
  GET_LEDGER,
} from "../actions/types";

const initialState = {
  userToken: null,
  mobile: null,
  homelist: [],
  sliderList: [],
  categorydetail: [],
  cardItems: [],
  likeItems: [],
  likeListIds: [],
  orderlist: [],
  sparepartlist: [],
  sparepartdetail: [],
  totalprice: 0,
  gridid: 1,
  appversion: null,
  version: null,
  customerlist: [],
  selectedcustomer: { value: 0 },
  getdiscount: "",
  getcustomeradd: [],
  getledger: [],
  totalcartprice: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_OUT:
      return {
        ...state,
        userToken: null,
        appversion: null,
      };
    case HOME_LOADING:
      return {
        ...state,
        homeloading: true,
        cardItems: [],
      };
    case HOME_LOADING_ERROR:
      return {
        ...state,
        homeloading: false,
        cardTotalQty:
          action.payload == "SET_CARD_QTY_AND_TOTAL" ? 0 : state.cardTotalQty,
        totalcartprice:
          action.payload == "SET_CARD_QTY_AND_TOTAL" ? 0 : state.totalcartprice,
        sparepartdetail:
          action.payload === "SPARE_PARTS" ? [] : state.sparepartdetail,
        sparepartlist:
          action.payload === "SPARE_PARTS_DETAIL" ? [] : state.sparepartlist,
        orderlist: action.payload === "GET_ORDER" ? [] : state.orderlist,
      };

    case HOME_LIST:
      return {
        ...state,
        categorydetail: [],
        homelist: action.payload,
        homeloading: false,
        sliderList: action.sliderpayload,
        version: action.payloadversion,
      };
    case CATEGORY_DETAIL:
      return {
        ...state,
        categorydetail: action.payload,
        homeloading: false,
        cartqty: state.cardItems,
      };

    case SET_CARD_QTY_AND_TOTAL:
      return {
        ...state,
        cardTotalQty: action.payloadQty,
        totalcartprice: action.payloadprice,
      };

    case GET_CARD_ITEMS:
      return {
        ...state,
        homeloading: false,
        cardItems: action.payload,
        totalprice: action.payloadtotalprice,
      };
    case REMOVE_CART:
      return {
        ...state,
        homeloading: false,
        cardItems: [],
      };
    case GET_LIKE_ITEMS:
      return {
        ...state,
        likeItems: action.payloadLikeItemsList,
        homeloading: false,
        likeListIds: action.payloadLikeItemsList.map((item) => item.id),
      };
    case GET_WISHLIST_ERROR:
      return {
        ...state,
        likeItems: [],
        likeListIds: [],
      };
    case REMOVE_WISHLIST:
      return {
        ...state,
        homeloading: false,
      };

    case LIKE_ITEM_VALUE_ADD:
      return {
        ...state,
        likeListIds: [...new Set([action.payloadLikeId, ...state.likeListIds])],
      };
    case UNLIKE_ITEM_VALUE_REMOVE:
      return {
        ...state,
        likeListIds: state.likeListIds.filter(
          (item) => action.payloadUnLikeId !== item
        ),
      };
    case GET_ORDER:
      return {
        ...state,
        orderlist: action.payload,
        homeloading: false,
      };
    case PLACE_ORDER:
      return {
        ...state,
        homeloading: false,
      };
    case SPARE_PARTS:
      return {
        ...state,
        sparepartlist: action.payload,
        homeloading: false,
      };
    case SPARE_PARTS_DETAIL:
      return {
        ...state,
        sparepartdetail: action.payload,
        homeloading: false,
      };
    case GRID:
      return {
        ...state,
        gridid: action.payload,
      };
    case APP_VERSION:
      return {
        ...state,
        appversion: action.payload,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customerlist: action.payload,
        homeloading: false,
      };
    case SELECTED_CUSTOMER_ID:
      return {
        ...state,
        selectedcustomer: action.payload,
      };
    case GET_DISCOUNT:
      return {
        ...state,
        getdiscount: action.payload == 0 ? "" : action.payload,
      };
    case CUSTOMER_ADD:
      return {
        ...state,
        customeraddloading: false,
      };
    case CUSTOMER_ADD_LOADING:
      return {
        customeraddloading: true,
      };
    case CUSTOMER_ADD_ERROR:
      return {
        customeraddloading: false,
      };
    case GET_CUSTOMER_ADD:
      return {
        ...state,
        getcustomeradd: action.payload,
        homeloading: false,
      };
    case GET_LEDGER:
      return {
        ...state,
        getledger: action.payload,
        homeloading: false,
      };
    default:
      return state;
  }
};
