import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { editOrderItemAction } from "../../../redux/actions/productActions";
import { Link, useNavigate } from "react-router-dom";

export default function OrderItem({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editOrderItemPress = () => {
    // dispatch(editOrderItemAction(item.id, navigate));
  };

  const viewFullPdfPress = () => {
    navigate("/pdf");
  };

  const { selectedcustomer } = useSelector((state) => state.productState);

  const statusarr = [
    { code: 0, status: "pending", color: "#FFFF8F" },
    { code: 1, status: "approve", color: "#7DF9FF" },
    { code: 2, status: "inprogress", color: "#CF9FFF" },
    { code: 3, status: "delivered", color: "#50C878" },
    { code: 4, status: "rejected", color: "#FF5733 " },
  ];

  return (
    <div className="col-md-4  mt-4  text-center">
      <div
        className="card shadow"
        style={{ backgroundColor: statusarr[item.status].color }}
      >
        <div className="card-body">
          <h5 className="card-title  pb-2 fw-bold">
            Order ID #{item?.order_id}
          </h5>
          <hr className="mt-0" />

          <p className="card-text"> Order Date: {item?.created_at}</p>
          <p className="card-text">
            Status: {statusarr[item.status].status.toUpperCase()}
          </p>

          <Link
            to={{ pathname: "/pdf" }}
            state={{
              item: item.id,
              itemId: item.id,
              pdfUrl: `https://dyk10x.shop/reactapi/order/order_view/${item.id}/${selectedcustomer?.value}`,
            }}
            className="btn btn-dark col-md-6 mt-2"
          >
            PDF
          </Link>
        </div>
      </div>
    </div>
  );
}
