import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectedCustomerAction } from "../../redux/actions/productAction";
import { useEffect } from "react";
import toast from "react-hot-toast";

const CustomerModal = () => {
  const dispatch = useDispatch();
  const [customer, setcustomer] = useState(null);

  const { customerlist, selectedcustomer } = useSelector(
    (state) => state.productState
  );

  const selectedOnChange = (e) => {
    dispatch(selectedCustomerAction(e));
    // window.$("#CustomerModal").modal("hide");
  };

  return (
    <div
      class="modal fade"
      id="CustomerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Add Customer
            </h1>
            {/* <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> */}
          </div>
          <div class="modal-body">
            <label className="h5 fw-bold mt-2">Select Customer</label>

            <div className="">
              <Select
                defaultValue={selectedcustomer}
                onChange={(e) => selectedOnChange(e)}
                options={customerlist}
              />
            </div>
          </div>
          <div class="modal-footer">
            {/* <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button> */}
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                console.log(selectedcustomer);
                if (selectedcustomer?.value) {
                  window.$("#CustomerModal").modal("hide");
                } else {
                  toast.error("Please Select Customer");
                }
              }}
            >
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
