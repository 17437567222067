import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";

const Diagram = () => {
  const location = useLocation();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = async () => {
    const url = location?.state?.url;
    const a = document.createElement("a");
    a.href = url;
    a.download = "document.pdf";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <object
        data={location?.state?.url}
        type="application/pdf"
        width="100%"
        height="100%"
      ></object>
    </div>
  );
};

export default Diagram;
