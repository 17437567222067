import React from "react";
import { useSelector } from "react-redux";
import { APP_VERSION } from "../../redux/actions/types";

const AppVersion = (version, appversion, dispatch) => {
  if (appversion == null) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Makes sure the page reloads. Changes are only visible after you refresh.

    dispatch({ type: APP_VERSION, payload: version });
  } else if (version != appversion) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Makes sure the page reloads. Changes are only visible after you refresh.

    localStorage.clear();
    dispatch({ type: APP_VERSION, payload: version });
    // }
  }

  return <></>;
};

export default AppVersion;
