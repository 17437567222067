import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CategoryDetailAction,
  getCardItemsAction,
  getWishlistAction,
} from "../../redux/actions/productAction";
import "./CategoryDetail.css";
import CategoryDetailitem from "./Component/CategoryDetailitem";
import CustomerModal from "../CustomerModal/CustomerModal";

const CategoryDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categorydetail, gridid } = useSelector((state) => state.productState);

  useEffect(() => {
    dispatch(CategoryDetailAction(location.state.id));
    dispatch(getWishlistAction());
    dispatch(getCardItemsAction());
    document.title = "Category | DYK ";
  }, []);

  return (
    <div className="container my-5">
      <CustomerModal />
      <div className={gridid == 2 ? "row g-2" : "row g-3"}>
        {categorydetail.map((item) => (
          <CategoryDetailitem item={item} />
        ))}
      </div>
    </div>
  );
};

export default CategoryDetail;
