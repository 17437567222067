import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { caching } from "../../App";
import {
  HomeAction,
  getCustomerAction,
} from "../../redux/actions/productAction";
import AppVersion from "../AppVersion/AppVersion";
import CustomerModal from "../CustomerModal/CustomerModal";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.authState);
  const { version, appversion, homelist, selectedcustomer, customerlist } =
    useSelector((state) => state.productState);

  useEffect(() => {
    if (username?.admin == 1) {
      dispatch(getCustomerAction(username?.id));
    }
    dispatch(HomeAction());
    document.title = "Home | DYK ";
  }, []);

  useEffect(() => {
    if (username?.admin == 1) {
      if (!selectedcustomer?.value) {
        window.$("#CustomerModal").modal("show");
      }
    }
  }, [customerlist]);

  useEffect(() => {
    setTimeout(() => {
      AppVersion(version, appversion, dispatch);
    }, 2000);
  }, [homelist]);

  console.log("selectedcustomer", selectedcustomer?.value);

  return (
    <>
      <CustomerModal />
      <div className="container my-5" style={{ fontFamily: "cairo" }}>
        <div className="text-center">
          <h1>WEL COME</h1>
          <h5>MR. {username?.name}</h5>
        </div>

        <div className="container">
          <div className="row mt-5 g-4">
            <Link to={"/order"} className="col-6 col-md-3 col-sm-6 ">
              <div
                style={{ backgroundColor: "#008183" }}
                className="card  mx-sm-1 p-3 click"
              >
                <div className="text-white text-center my-5">
                  <h4>Order</h4>
                </div>
              </div>
            </Link>

            <Link to={"/ledger"} className="col-6 col-md-3 col-sm-6">
              <div
                style={{ backgroundColor: "#8EAC50" }}
                className="card  mx-sm-1 p-3"
              >
                <div className="text-white text-center my-5">
                  <h4>Ledger</h4>
                </div>
              </div>
            </Link>
            <Link to={"/pendingorder"} className="col-6 col-md-3 col-sm-6">
              <div
                style={{ backgroundColor: "#8EAC50" }}
                className="card  mx-sm-1 p-3"
              >
                <div className="text-white text-center my-5">
                  <h4>Pending Order</h4>
                </div>
              </div>
            </Link>
            {selectedcustomer?.value ? (
              <Link to={"/customeradd"} className="col-6 col-md-3 col-sm-6 ">
                <div
                  style={{ backgroundColor: "#8EAC50" }}
                  className="card  mx-sm-1 p-3 h-100"
                >
                  <div className="text-white text-center my-5">
                    <h4>Customer Add</h4>
                  </div>
                </div>
              </Link>
            ) : (
              <></>
            )}
            <Link to={"/"} className="col-6 col-md-3 col-sm-6">
              <div
                style={{ backgroundColor: "#8EAC50" }}
                className="card  mx-sm-1 p-3 h-100"
              >
                <div className="text-white text-center my-5">
                  <h4>Up Coming</h4>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
