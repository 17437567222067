export const LOG_OUT = "LOG_OUT";
export const LOGIN = "LOGIN";
export const LOGIN_VERIFY = "LOGIN_VERIFY";

export const LOADING = "LOADING";
export const LOADING_ERROR = "LOADING_ERROR";

//product
export const HOME_LOADING = "HOME_LOADING";
export const HOME_LOADING_ERROR = "HOME_LOADING_ERROR";

export const HOME_LIST = "HOME_LIST";
export const CATEGORY_DETAIL = "CATEGORY_DETAIL";

export const GET_CARD_ITEMS = "GET_CARD_ITEMS";

export const GET_LIKE_ITEMS = "GET_LIKE_ITEMS";

export const LIKE_UNLIKE_SERVER_SIDE_LOADING =
  "LIKE_UNLIKE_SERVER_SIDE_LOADING";
export const LIKE_UNLIKE_SERVER_SIDE_ERROR_DONE =
  "LIKE_UNLIKE_SERVER_SIDE_ERROR_DONE";

export const LIKE_ITEM_VALUE_ADD = "LIKE_ITEM_VALUE_ADD";
export const UNLIKE_ITEM_VALUE_REMOVE = "UNLIKE_ITEM_VALUE_REMOVE";

export const SET_CARD_QTY_AND_TOTAL = "SET_CARD_QTY_AND_TOTAL";
export const REMOVE_CART = "REMOVE_CART";

export const REMOVE_WISHLIST = "REMOVE_WISHLIST";

export const GET_WISHLIST_ERROR = "GET_WISHLIST_ERROR";

export const PLACE_ORDER = "PLACE_ORDER";
export const GET_ORDER = "GET_ORDER";

export const SPARE_PARTS = "SPARE_PARTS";
export const SPARE_PARTS_DETAIL = "SPARE_PARTS_DETAIL";

export const GRID = "GRID";

export const APP_VERSION = "APP_VERSION";

export const GET_CUSTOMER = "GET_CUSTOMER";

export const GET_CUSTOMER_ADD = "GET_CUSTOMER_ADD";

export const SELECTED_CUSTOMER_ID = "SELECTED_CUSTOMER_ID";

export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";

export const GET_DISCOUNT = "GET_DISCOUNT";
export const CUSTOMER_ADD = "CUSTOMER_ADD";
export const CUSTOMER_ADD_LOADING = "CUSTOMER_ADD_LOADING";
export const CUSTOMER_ADD_ERROR = "CUSTOMER_ADD_ERROR";
export const GET_LEDGER = "GET_LEDGER";
