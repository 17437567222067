import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import { LedgerAction } from "../../redux/actions/authActons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { getLedgerAction } from "../../redux/actions/productAction";
// import DetailModal from "./DetailModal";

const LedgerDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //   const { data } = useSelector((state) => state.authState);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedimg, setSelectedimg] = useState("");

  useEffect(() => {
    document.title = "Detail | Ledger ";
  }, []);

  const data = [
    {
      date: "2023-06-12",
      clallan_no: "23445",
      clallan_img:
        "https://assets-global.website-files.com/6253f6e60f27498e7d4a1e46/6271a55db22aea4fa0cce308_Consulting-Invoice-Template-1-G.jpeg",
      lr_img:
        "https://www.zervant.com/prod/uploads/2022/02/Free-invoicing-page_En-Cover-picture.png",
      ebill_img:
        "https://www.inv24.com/components/Misc/download/templates/gb/images/thumbs/invoice_template_uk_pdf.jpg",
      gst_img:
        "https://www.inv24.com/components/Misc/download/templates/gb/images/thumbs/invoice_template_uk_pdf.jpg",
      parcel_img:
        "https://www.inv24.com/components/Misc/download/templates/gb/images/thumbs/invoice_template_uk_pdf.jpg",
    },
  ];

  console.log(location?.state?.date);

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={selectedimg}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}

      {data?.data?.length === 0 ||
      (Array.isArray(data?.data) && data?.data?.length === 0) ? (
        <>
          {(Array.isArray(data?.data) && data?.data?.length === 0) ||
          data?.data?.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "100vh",
              }}
            >
              <h4 className="my-auto">Ledger Not Found</h4>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="container my-5 px-1" style={{ fontFamily: "cairo" }}>
          <div className="position-relative ">
            <Link
              style={{
                position: "fixed",
                top: 90,
                right: 0,
                backgroundColor: "#008183",
              }}
              onClick={() => {
                window.scrollTo({
                  top: document.body.offsetHeight,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="btn btn-lg  btn-lg-square "
            >
              <i className="bi bi-arrow-down" style={{ color: "#fff" }} />
            </Link>
          </div>

          <div className="my-5 pb-5">
            <table class="table ">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Challan No</th>
                  <th scope="col">Challan</th>
                  <th scope="col">LR</th>
                  <th scope="col">E-Way Bill</th>
                  <th scope="col">GST Bill</th>
                  <th scope="col">Parcel Photo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    style={{
                      backgroundColor: "#fff",
                      justifyContent: "center",
                    }}
                  >
                    {location?.state?.date}
                  </th>
                  <td
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {location?.state?.challan_no}
                  </td>
                  <td
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setSelectedimg(location?.state?.challan);
                    }}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {location?.state?.challan && (
                      <img src={location?.state?.challan} style={styles.img} />
                    )}
                  </td>
                  <td
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setSelectedimg(location?.state?.lr);
                    }}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {location?.state?.lr && (
                      <img src={location?.state?.lr} style={styles.img} />
                    )}
                  </td>
                  <td
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setSelectedimg(location?.state?.way_bill);
                    }}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {location?.state?.way_bill && (
                      <img src={location?.state?.way_bill} style={styles.img} />
                    )}
                  </td>
                  <td
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setSelectedimg(location?.state?.gst_bill);
                    }}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {location?.state?.way_bill && (
                      <img src={location?.state?.gst_bill} style={styles.img} />
                    )}
                  </td>
                  <td
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setSelectedimg(location?.state?.parcel_photo);
                    }}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {location?.state?.parcel_photo && (
                      <img
                        src={location?.state?.parcel_photo}
                        style={styles.img}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default LedgerDetail;

const styles = {
  img: { height: "150px", width: "150px" },
};
