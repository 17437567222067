import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ProductDetail.css";
import {
  addCardItemsAction,
  addRemoveLikeItemsAction,
  getCardItemsAction,
} from "../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";

const ProductDetail = () => {
  const location = useLocation();
  const { pathname } = useLocation();
  const [qtyValue, setQtyValue] = useState(0);
  const dispatch = useDispatch();
  const {
    item: {
      id,
      productImage,
      images,
      productTitle,
      productSubTitle,
      productSKUCode,
      containerUnit,
      price,
      productDescription,
      productFeatures,
      quantity,
    },
  } = location.state;

  const { likeListIds, cardItems } = useSelector((state) => state.productState);

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-select .select");

    const imgBtns = [...imgs];

    let imgId = 1;

    imgBtns.forEach((imgItem) => {
      imgItem.addEventListener("click", (event) => {
        event.preventDefault();
        imgId = imgItem.dataset.id;

        slideImage();
      });
    });

    function slideImage() {
      const displayWidth = document.querySelector(
        ".img-showcase img:first-child"
      )?.clientWidth;

      document.querySelector(".img-showcase").style.transform = `translateX(${
        -(imgId - 1) * displayWidth
      }px)`;
    }

    window.addEventListener("resize", slideImage);
  }, []);

  useEffect(() => {
    const items = cardItems.filter((item) => id == item.id);

    setQtyValue(parseFloat(items[0]?.quantity ? items[0]?.quantity : 0));

    document.title = "ProductDetail | DYK ";
  }, [pathname]);

  const hardIconComponent = likeListIds.find(
    (likeitemid) => likeitemid === id
  ) ? (
    <div>
      <i
        className="fa-solid fa-heart fs-4 me-3"
        style={{
          color: "#e84040",
          position: "absolute",
          top: "10px",
          right: "0px",
        }}
        onClick={() => dispatch(addRemoveLikeItemsAction(id, "unlike"))}
      ></i>
    </div>
  ) : (
    <div>
      <i
        className="fa-solid fa-heart fs-4 me-3"
        style={{
          color: "grey",
          position: "absolute",
          top: "10px",
          right: "0px",
        }}
        onClick={() => dispatch(addRemoveLikeItemsAction(id, "like"))}
      ></i>
    </div>
  );

  return (
    <div>
      <div className="card-wrapper ">
        <div className="card product-card">
          {/* <!-- card left --> */}
          <div className="product-imgs">
            <div className="img-display">
              <div className="img-showcase">
                {images.length > 0 ? (
                  <>
                    {images.map((item) => (
                      <img src={item.image} alt="" />
                    ))}
                  </>
                ) : (
                  <img src={productImage} alt="" />
                )}
              </div>
            </div>
            <div className="img-select ">
              {images.length == 1 ? (
                <></>
              ) : (
                <>
                  {images.map((item, index) => (
                    <div className="img-item">
                      <div className="select" data-id={index + 1}>
                        <img
                          className="border"
                          src={item.image}
                          alt=""
                          onClick={onchange}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          {/* <!-- card right --> */}
          <div className="product-content">
            <h2 className="product-title">{productTitle}</h2>

            <div className="product-price">
              <p className="new-price">{productSubTitle}</p>
              <p className="last-price">{productSKUCode}</p>
              <p className="last-price">{containerUnit} Pieces/Box</p>
              <p className="last-price">INR {price} </p>
            </div>

            <div className="product-detail">
              <h2 className="mt-3 ">
                Specifications <hr className="hr" />
              </h2>
              {productFeatures.map((item) => (
                <ul>
                  <li>
                    {item?.label}: <span>{item?.value}</span>
                  </li>
                </ul>
              ))}

              <h2>
                Description
                <hr className="hr" />
              </h2>
              <p>{productDescription}</p>
            </div>

            <div className="purchase-info d-flex ">
              <div className="d-flex align-items-center justify-content-around ">
                <div
                  className="input-group d-flex "
                  style={{ width: "140px", marginTop: "5px" }}
                >
                  <div className="input-group-prepend">
                    <button
                      onClick={() => {
                        if (qtyValue > 1) {
                          dispatch(
                            addCardItemsAction(id, qtyValue - 1, "update")
                          );
                          setQtyValue((valueQty) => valueQty - 1);
                        }
                      }}
                      className="btn btn-primary js-btn-minus "
                      type="button"
                    >
                      &minus;
                    </button>
                  </div>
                  <input
                    type="text"
                    style={{
                      color: "#000",
                    }}
                    className="form-control  text-center "
                    value={qtyValue}
                    placeholder=""
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => {
                        dispatch(addCardItemsAction(id, 1, "add"));
                        setQtyValue((valueQty) => valueQty + 1);
                      }}
                      className="btn btn-outline-white btn-primary js-btn-plus "
                      type="button"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              {hardIconComponent}
            </div>

            {/* <div className="social-links">
              <p>Share At: </p>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a href="#">
                <i className="fab fa-pinterest"></i>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
