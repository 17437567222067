import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderListAction } from "../../redux/actions/productAction";
import OrderItem from "./Components/OrderItem";

export default function Order() {
  const dispatch = useDispatch();
  const { listOrderLoading, listOrderItems, listOrderError, orderlist } =
    useSelector((state) => state.productState);

  useEffect(() => {
    dispatch(getOrderListAction());
    document.title = "Order | DYK ";
  }, []);

  return (
    <>
      {(Array.isArray(orderlist) && orderlist?.length === 0) ||
      orderlist?.length === 0 ? (
        <div
          className=" d-flex justify-content-center "
          style={{
            height: "100vh",
          }}
        >
          <h4 className="my-auto">Your Order List Is Empty</h4>
        </div>
      ) : (
        <div className="container my-5  ">
          <div className="row">
            {orderlist?.map((item) => (
              <OrderItem item={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
