import axios from "axios";
import toast from "react-hot-toast";

import {
  LOG_OUT,
  LOADING_ERROR,
  HOME_LIST,
  HOME_LOADING_ERROR,
  HOME_LOADING,
  CATEGORY_DETAIL,
  CARD_ITEM_INCREMENT_DECREMENT_SERVER_SIDE_LOADING,
  SET_CARD_QTY_AND_TOTAL,
  CARD_ITEM_INCREMENT_DECREMENT_SERVER_SIDE_ERROR_OR_DONE,
  GET_CARD_ITEMS,
  REMOVE_CART,
  GET_LIKE_ITEMS,
  REMOVE_WISHLIST,
  LIKE_ITEM_VALUE_ADD,
  UNLIKE_ITEM_VALUE_REMOVE,
  GET_WISHLIST_ERROR,
  GET_ORDER,
  PLACE_ORDER,
  SPARE_PARTS,
  SPARE_PARTS_DETAIL,
  GET_CUSTOMER,
  SELECTED_CUSTOMER_ID,
  UPDATE_DISCOUNT,
  GET_DISCOUNT,
  CUSTOMER_ADD,
  CUSTOMER_ADD_LOADING,
  CUSTOMER_ADD_ERROR,
  GET_CUSTOMER_ADD,
  GET_LEDGER,
} from "./types";
import { caching } from "../../App";
import AppVersion from "../../Modules/AppVersion/AppVersion";

export const HomeAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/category/getlist/${"0.1.8"}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
    // data: data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;

      if (serverResponse?.success === 1) {
        dispatch({
          type: HOME_LIST,
          payload: serverResponse.data,
          sliderpayload: serverResponse.banner,
          payloadversion: serverResponse.version,
        });

        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: LOADING_ERROR,
        });
        toast.error(serverResponse.msg);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const CategoryDetailAction = (id) => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/category/getproductlist/${id}/${selectedcustomer?.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
    // data: data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;

      console.log(serverResponse);

      if (serverResponse?.success === 1) {
        dispatch({
          type: CATEGORY_DETAIL,
          payload: serverResponse.data,
        });

        if (serverResponse && serverResponse) {
          dispatch({
            type: GET_DISCOUNT,
            payload: serverResponse.discount,
          });
        } else {
          toast.success("discount not found");
        }

        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: LOADING_ERROR,
        });

        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const addCardItemsAction =
  (projectId, productQty = "0", carttype) =>
  async (dispatch, getState) => {
    try {
      const {
        authState: { userToken },
        productState: { selectedcustomer },
      } = getState();

      var dataObj = {
        product_id: projectId,
        quantity: productQty,
        cart_type: carttype,
      };

      var config = {
        method: "post",
        url: `https://dyk10x.shop/reactapi/cart/add/${selectedcustomer?.value}`,
        headers: {
          "If-Range": userToken,
          "Content-Type": "multipart/form-data",
        },
        data: dataObj,
      };

      axios(config)
        .then(function (response) {
          if (
            response &&
            response.data &&
            response.data.message &&
            response.data.success === 0 &&
            response.data.message.includes("Invalid Token")
          ) {
            dispatch({ type: LOG_OUT });
          }

          console.log(response.data.price, "prrrrrrr");

          if (response && response.data.qty) {
            dispatch({
              type: SET_CARD_QTY_AND_TOTAL,
              payloadQty: parseInt(response.data.qty),
              payloadprice: response.data.price,
            });
            dispatch(getCardItemsAction());
          } else {
            toast.error("something is wrong try again");
          }
        })
        .catch(function (error) {
          toast.error("server response failed");
        });
    } catch (e) {}
  };

export const getCardItemsAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  // dispatch({
  //   type: HOME_LOADING,
  // });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/cart/get/${selectedcustomer?.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: GET_CARD_ITEMS,
          payload: serverResponse.data,
          payloadidqty: serverResponse.data.quantity,
          payloadtotalprice: serverResponse.price,
        });
        dispatch({
          type: SET_CARD_QTY_AND_TOTAL,
          payloadQty: parseInt(response.data.qty),
          payloadprice: response.data.price,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
          payload: "SET_CARD_QTY_AND_TOTAL",
        });
        dispatch({
          type: HOME_LOADING_ERROR,
          payload: "GET_CARD_ITEMS",
        });

        // toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const removeCardItemAction = (id) => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  // dispatch({
  //   type: HOME_LOADING,
  // });

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/cart/remove/${selectedcustomer?.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "multipart/form-data",
    },
    data: { product_id: id },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      if (serverResponse?.success === 1) {
        dispatch({
          type: REMOVE_CART,
        });
        // toast.success(serverResponse.message);
        dispatch(getCardItemsAction());
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const getWishlistAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  // dispatch({
  //   type: HOME_LOADING,
  // });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/wishlist/get`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      if (serverResponse?.success === 1) {
        dispatch({
          type: GET_LIKE_ITEMS,
          payloadLikeItemsList: serverResponse.data,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: GET_WISHLIST_ERROR,
        });
        // toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_WISHLIST_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const addRemoveLikeItemsAction =
  (projectId, type = "like") =>
  async (dispatch, getState) => {
    if (type === "like") {
      dispatch({
        type: LIKE_ITEM_VALUE_ADD,
        payloadLikeId: projectId,
      });
    } else if (type === "unlike") {
      dispatch({
        type: UNLIKE_ITEM_VALUE_REMOVE,
        payloadUnLikeId: projectId,
      });
    }

    try {
      const {
        authState: { userToken },
      } = getState();

      var dataObj = { product_id: projectId };

      const likeUnlikeUrl =
        type === "like"
          ? `https://dyk10x.shop/reactapi/wishlist/add`
          : `https://dyk10x.shop/reactapi/wishlist/remove`;

      var config = {
        method: "post",
        url: likeUnlikeUrl,
        headers: {
          "If-Range": userToken,
          "Content-Type": "multipart/form-data",
        },
        data: dataObj,
      };

      axios(config)
        .then(function (response) {
          if (
            response &&
            response.data &&
            response.data.message &&
            response.data.success === 0 &&
            response.data.message.includes("Invalid Token")
          ) {
            dispatch({ type: LOG_OUT });
          }

          if (response && response.data && response.data.success) {
            toast.success(
              type === "like"
                ? "Item successfully added into wishlist"
                : "Item successfully removed from wishlist"
            );

            if (type === "unlike") {
              dispatch(getWishlistAction());
            }
          } else {
            toast.error(response.data.message);
          }
        })
        .catch(function (error) {
          toast.error("something is wrong");
        });
    } catch (e) {}
  };

export const placeOrderAction = (navigate, remark) => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/order/placeorder/${selectedcustomer?.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "multipart/form-data",
    },
    data: { remark: remark },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      if (serverResponse?.success === 1) {
        dispatch({
          type: PLACE_ORDER,
        });
        toast.success(serverResponse.message);
        dispatch(getCardItemsAction());
        // navigate("/pendingorder");
        navigate("/pdf", {
          state: {
            item: serverResponse.order_id,
            itemId: serverResponse.order_id,
            pdfUrl: `https://dyk10x.shop/reactapi/order/order_view/${serverResponse.order_id}/${selectedcustomer?.value}`,
          },
        });
        // window.$("#placeordermodal").modal("hide");
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const getOrderListAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/order/getorder`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      if (serverResponse?.success === 1) {
        dispatch({
          type: GET_ORDER,
          payload: serverResponse.data,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
          payload: "GET_ORDER",
        });
        // toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const getSparePartsAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/category/getsparepartsproductlist`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;

      if (serverResponse?.success === 1) {
        dispatch({
          type: SPARE_PARTS,
          payload: serverResponse.data,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
          payload: "SPARE_PARTS",
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const getSparePartsDetailAction = (id) => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/category/getspaceparts/${id}/${selectedcustomer?.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: SPARE_PARTS_DETAIL,
          payload: serverResponse.data,
        });
        if (serverResponse && serverResponse) {
          dispatch({
            type: GET_DISCOUNT,
            payload: serverResponse.discount,
          });
        } else {
          toast.success("discount not found");
        }

        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
          payload: "SPARE_PARTS_DETAIL",
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const getCustomerAction = (id) => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  console.log(id);

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/customer/getlist/${id}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: GET_CUSTOMER,
          payload: serverResponse.data,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const selectedCustomerAction = (id) => (dispatch, getState) => {
  dispatch({
    type: SELECTED_CUSTOMER_ID,
    payload: id,
  });
};

export const DiscountUpdatAction =
  (
    selectedcustomer = 0,
    customer_id = 0,
    category_id,
    discount = 0,
    location
  ) =>
  (dispatch, getState) => {
    const {
      authState: { userToken, username },
      // productState: { selectedcustomer },
    } = getState();

    dispatch({
      type: HOME_LOADING,
    });

    const data = {
      extra_user_id: selectedcustomer,
      customer_id: customer_id,
      category_id: category_id,
      discount: discount,
    };

    console.log(data);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://dyk10x.shop/reactapi/customer/setdiscount`,
      headers: {
        "If-Range": userToken,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        const serverResponse = response.data;
        console.log(serverResponse);
        if (serverResponse?.success === 1) {
          dispatch({
            type: UPDATE_DISCOUNT,
          });
          if (location.pathname == "/categorydetail") {
            dispatch(CategoryDetailAction(category_id));
          }

          if (location.pathname == "/sparepartdetail") {
            dispatch(getSparePartsDetailAction(category_id));
          }

          // toast.success(serverResponse.message);
        } else {
          dispatch({
            type: HOME_LOADING_ERROR,
          });
          if (
            serverResponse.message == "The extra_user_id   field is required.\n"
          ) {
            toast.error("Please select customer");
          } else {
            toast.error(serverResponse.message);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        console.log(error);
        if (error.response) {
          let message = error.response.data.message;
          let status = error.response.status;
          if (status == 401) {
            alert("unauthorized");
            dispatch({
              type: LOG_OUT,
            });
          } else if (status == 404) {
            alert("Page Not Found");
            dispatch({
              type: LOG_OUT,
            });
          }
        } else {
          alert(error);
        }
      });
  };

export const CustomerAddAction =
  (name, email, mobile, id) => (dispatch, getState) => {
    const {
      authState: { userToken },
    } = getState();

    dispatch({
      type: CUSTOMER_ADD_LOADING,
    });

    const data = {
      name: name,
      email: email,
      mobile: mobile,
      staff_id: id,
    };

    console.log(data);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://dyk10x.shop/reactapi/customer/add`,
      headers: {
        "If-Range": userToken,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        const serverResponse = response.data;
        if (serverResponse?.success == 1) {
          dispatch({
            type: CUSTOMER_ADD,
          });
          toast.success(serverResponse.message);
        } else {
          dispatch({
            type: CUSTOMER_ADD_ERROR,
          });

          toast.error(serverResponse.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: CUSTOMER_ADD_ERROR,
        });
        console.log(error);
        if (error.response) {
          let message = error.response.data.message;
          let status = error.response.status;
          if (status == 401) {
            alert("unauthorized");
            dispatch({
              type: LOG_OUT,
            });
          } else if (status == 404) {
            alert("Page Not Found");
            dispatch({
              type: LOG_OUT,
            });
          }
        } else {
          alert(error);
        }
      });
  };

export const getCustomerAddAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/customer/getstafflist`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: GET_CUSTOMER_ADD,
          payload: serverResponse.data,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const getLedgerAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  dispatch({
    type: HOME_LOADING,
  });

  console.log("selectedcustomer", selectedcustomer);

  // return false;

  var dateobj = new Date();
  const date = dateobj.getDate();
  const month = dateobj.getMonth() + 1;
  const year = dateobj.getFullYear();
  console.log(`${year}-${month}-${date}`);

  const data = {
    from: "1997-07-28",
    to: `${year}-${month}-${date}`,
  };

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/home/get_ledger/${selectedcustomer.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
    data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: GET_LEDGER,
          payload: serverResponse,
        });
        // toast.success(serverResponse.message);
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};

export const QueryBoxAction = (remark) => (dispatch, getState) => {
  const {
    authState: { userToken },
    productState: { selectedcustomer },
  } = getState();

  const data = {
    remark: remark,
  };

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://dyk10x.shop/reactapi/home/query_box/${selectedcustomer.value}`,
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
    data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        toast.success(serverResponse.message);
        window.$("#QueryBoxMoal").modal("hide");
      } else {
        dispatch({
          type: HOME_LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: HOME_LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch({
            type: LOG_OUT,
          });
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch({
            type: LOG_OUT,
          });
        }
      } else {
        alert(error);
      }
    });
};
