import React, { useEffect } from "react";
import CartItem from "./Components/CartItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardItemsAction,
  placeOrderAction,
} from "../../redux/actions/productAction";
import PlaceOrderModal from "./Components/PlaceOrderModal";
import { useNavigate } from "react-router-dom";
import CustomerModal from "../CustomerModal/CustomerModal";
import RemarkModal from "./Components/RemarkModal";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cardItems, totalqty, selectedcustomer } = useSelector(
    (state) => state.productState
  );
  const { username } = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(getCardItemsAction());
    document.title = "Cart | DYK ";
  }, []);

  console.log(cardItems);

  return (
    <>
      {Array.isArray(cardItems) && cardItems.length === 0 ? (
        <div
          className=" d-flex justify-content-center "
          style={{
            height: "100vh",
          }}
        >
          <h4 className="my-auto text-dark">Cart is empty</h4>
        </div>
      ) : (
        <>
          <div className="container  my-5">
            <div className="row ">
              {cardItems?.map((item) => (
                <React.Fragment key={item.id}>
                  <CartItem item={item} />
                </React.Fragment>
              ))}
            </div>
          </div>
          <PlaceOrderModal />
          <CustomerModal />
          <RemarkModal />
          <button
            type="button"
            className="btn fixed-bottom py-2 fs-4 fw-bold"
            // data-bs-toggle="modal"
            // data-bs-target="#placeordermodal"
            style={{ backgroundColor: "green", color: "white" }}
            onClick={() => {
              if (username?.admin == 1) {
                if (!selectedcustomer?.value) {
                  window.$("#CustomerModal").modal("show");
                } else {
                  window.$("#RemarkModal").modal("show");
                }
              } else {
                window.$("#RemarkModal").modal("show");
              }
            }}
          >
            Place Order
          </button>
        </>
      )}
    </>
  );
};

export default Cart;
