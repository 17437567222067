import "./App.css";
import Login from "./Modules/Login/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./Modules/ProtectedRoute";
import LoginVerify from "./Modules/Login/LoginVerify";
import Home from "./Modules/Home/Home";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Modules/Header/Header";
import Lorder from "./Modules/Lorder/Lorder";
import CategoryDetail from "./Modules/CategoryDetail/CategoryDetail";
import ProductDetail from "./Modules/ProductDetail/ProductDetail";
import { useEffect, useRef, useLayoutEffect, Suspense } from "react";
import Cart from "./Modules/Cart/Cart";
import Wishlist from "./Modules/Wishlist/Wishlist";
import Order from "./Modules/Order/Order";
import SparePart from "./Modules/SparePart/SparePart";
import SparePartDetail from "./Modules/SparePartDetail/SparePartDetail";
import Dashboard from "./Modules/Dashboard/Dashboard";
import Diagram from "./Diagram/Diagram";
import PdfView from "./Modules/Order/Components/PdfView";
import packageJson from "../package.json";
import { GlobalDebug } from "./Modules/remove-consoles";
import { HomeAction } from "./redux/actions/productAction";
import AddAdmin from "./Modules/AddAdmin/AddAdmin";
import Ledger from "./Modules/Ledger/Ledger";
import LedgerDetail from "./Modules/LedgerDetail/LedgerDetail";

function App() {
  const dispatch = useDispatch();
  const { userToken, loading } = useSelector((state) => state.authState);
  const { homeloading } = useSelector((state) => state.productState);

  function ScrollToTop() {
    const location = useLocation();

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  }

  useEffect(() => {
    process.env.NODE_ENV === "production" && GlobalDebug(false);
  }, []);

  return (
    <>
      <HashRouter>
        {/* <ScrollToTop /> */}
        <Toaster />

        {userToken ? (
          <>
            <Header />
          </>
        ) : null}

        {loading || homeloading ? <Lorder /> : null}

        <Routes>
          <Route path="/" element={!userToken ? <Login /> : <Dashboard />} />
          <Route path="/loginverify" element={<LoginVerify />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/order" element={<Home />} />
            <Route path="/categorydetail" element={<CategoryDetail />} />
            <Route path="/productdetail" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/pendingorder" element={<Order />} />
            <Route path="/sparepart" element={<SparePart />} />
            <Route path="/sparepartdetail" element={<SparePartDetail />} />
            <Route path="/Diagram" element={<Diagram />} />
            <Route path="/pdf" element={<PdfView />} />
            <Route path="/customeradd" element={<AddAdmin />} />
            <Route path="/ledger" element={<Ledger />} />
            <Route path="/ledgerdetail" element={<LedgerDetail />} />
          </Route>
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
