import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  CategoryDetailAction,
  addCardItemsAction,
  addRemoveLikeItemsAction,
  removeCardItemAction,
} from "../../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Lightbox from "react-image-lightbox";

const CategoryDetailitem = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [qtyValue, setQtyValue] = useState(0);
  const { likeListIds, homeloading, gridid, getdiscount } = useSelector(
    (state) => state.productState
  );

  const { username } = useSelector((state) => state.authState);

  const LikeToUnLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "unlike"));
  };

  const UnLikeToLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "like"));
  };

  const hardIconComponent = likeListIds.find(
    (likeitemid) => likeitemid === item.id
  ) ? (
    <div>
      <i
        className="fa-solid fa-heart fs-4 me-3"
        style={{
          color: "#e84040",
          position: "absolute",
          top: "10px",
          right: "0px",
        }}
        onClick={() => LikeToUnLikeFun()}
      ></i>
    </div>
  ) : (
    <div>
      <i
        className="fa-solid fa-heart fs-4 me-3"
        style={{
          color: "grey",
          position: "absolute",
          top: "10px",
          right: "0px",
        }}
        onClick={() => UnLikeToLikeFun()}
      ></i>
    </div>
  );

  function filterField(event) {
    if (username?.admin == 1) {
      if (getdiscount > 0) {
        setQtyValue(Number(event.target?.value));
      } else {
        toast.error("Please add discount");
      }
    } else {
      setQtyValue(Number(event.target?.value));
    }
  }

  let vel = 1;

  useEffect(() => {
    setQtyValue(
      parseFloat(
        item.quantity
          ? item.quantity
          : item.quantity == null
          ? 0
          : item.quantity
      )
    );
  }, [item]);

  window.addEventListener("beforeunload", (event) => {
    event.preventDefault();
    event.returnValue = "";
  });

  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const image = item.images;

  return (
    <>
      <div
        className={
          gridid == 2 ? "col-lg-2 col-6 col-md-3 " : "col-6 col-md-4 col-lg-2"
        }
      >
        <div
          className={
            gridid == 2
              ? "d-flex shadow "
              : " shadow rounded overflow-hidden h-100 "
          }
          style={{ backgroundColor: "aliceblue" }}
        >
          <div className="position-relative ">
            {gridid == 1 ? <>{hardIconComponent}</> : null}
            {item.colorcode}
            <div
              className={gridid == 1 ? "color-data" : "color-data-small"}
              style={{
                background:
                  item.color_data == null
                    ? null
                    : item.color_data == "yellow"
                    ? "#F69312"
                    : item.color_data,
              }}
            >
              {console.log(item)}
              {item.stock_content}
            </div>
            <div onClick={() => setIsOpen(true)}>
              <img
                className="img-fluid "
                src={item?.productImageThumb}
                alt=""
                style={{
                  // flex: 1,
                  width: gridid == 2 ? "80px" : "180px",
                  height: gridid == 2 ? "80px" : "180px",
                }}
              />
            </div>

            {isOpen && item.images.length > 0 ? (
              <Lightbox
                mainSrc={item.images[imgIndex].image}
                nextSrc={item.images[(imgIndex + 1) % item.images.length].image}
                prevSrc={
                  item.images[
                    (imgIndex + item.images.length - 1) % item.images.length
                  ].image
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setImgIndex(
                    (imgIndex + item.images.length - 1) % item.images.length
                  )
                }
                onMoveNextRequest={() =>
                  setImgIndex((imgIndex + 1) % item.images.length)
                }
              />
            ) : isOpen && item?.productImageThumb ? (
              <Lightbox
                mainSrc={item?.productImageThumb}
                onCloseRequest={() => setIsOpen(false)}
              />
            ) : null}

            <button
              style={{ display: gridid == 2 ? "none" : "" }}
              onClick={() => {
                navigate("/productdetail", {
                  state: {
                    item: item,
                    qty: qtyValue,
                  },
                });
              }}
              className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4 btn btn-primary"
            >
              View
            </button>
          </div>

          <div className={gridid == 2 ? "about-product-grid" : "about-product"}>
            <div
              className={
                gridid == 2 ? "d-flex justify-content-between" : "mb-2"
              }
            >
              <div>
                <h5 className="mb-0 product-title">{item?.productTitle}</h5>
                <h5 className="mb-0">{item?.productSubTitle}</h5>
                <h5 className="mb-0">{item?.productSKUCode}</h5>
                <h5>{item?.containerUnit}Pieces/Box</h5>
                <h5 className="fw-bold text-bg-danger ">RS. {item?.price}</h5>
                <h5 className="">
                  Pcs: {Number(item?.containerUnit) * qtyValue}
                </h5>
              </div>
              <i
                style={{ display: gridid == 1 ? "none" : "" }}
                class="fa-solid fa-expand"
                onClick={() => {
                  navigate("/productdetail", {
                    state: {
                      item: item,
                      qty: qtyValue,
                    },
                  });
                }}
              ></i>
            </div>
            <div className="d-flex">
              <div className="d-flex justify-content-between ">
                <div className="d-flex align-items-center justify-content-around">
                  <div
                    className="input-group d-flex"
                    style={{ maxWidth: "120px" }}
                  >
                    <div className="input-group-prepend">
                      <button
                        onClick={() => {
                          console.log(qtyValue);
                          if (qtyValue == 1) {
                            setQtyValue(0);
                            dispatch(removeCardItemAction(item.id));
                          } else {
                            if (qtyValue > 0) {
                              dispatch(
                                addCardItemsAction(
                                  item.id,
                                  qtyValue - vel,
                                  "update"
                                )
                              );
                              setQtyValue((valueQty) => valueQty - 1);
                            }
                          }
                        }}
                        className={
                          gridid == 2
                            ? " grid-button btn btn-outline-primary js-btn-minus"
                            : "btn btn-outline-primary js-btn-minus"
                        }
                        type="button"
                      >
                        &minus;
                      </button>
                    </div>
                    <input
                      inputMode="numeric"
                      style={{ backgroundColor: qtyValue > 0 ? "	#FAA0A0" : "" }}
                      type="number"
                      className={
                        gridid == 2
                          ? "grid-button form-control text-center"
                          : "form-control text-center "
                      }
                      value={qtyValue}
                      placeholder=""
                      aria-label="Example text with button addon"
                      aria-describedby="button-addon1"
                      onChange={(e) => {
                        filterField(e);
                      }}
                      onFocus={() => {
                        if (qtyValue == 0) setQtyValue("");
                      }}
                      onBlur={() => {
                        if (qtyValue == 0) {
                          setQtyValue(0);
                        }
                        if (username?.admin == 1) {
                          if (getdiscount != 0) {
                            dispatch(
                              addCardItemsAction(
                                item.id,
                                qtyValue ? qtyValue : 1,
                                "update"
                              )
                            );
                          } else {
                            toast.error("Please add discount");
                            setQtyValue(0);
                          }
                        } else {
                          dispatch(
                            addCardItemsAction(
                              item.id,
                              qtyValue ? qtyValue : 1,
                              "update"
                            )
                          );
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        onClick={() => {
                          if (username?.admin == 1) {
                            console.log("first");
                            if (getdiscount != 0) {
                              setQtyValue((valueQty) => valueQty + 1);
                              dispatch(addCardItemsAction(item.id, vel, "add"));
                            } else {
                              toast.error("Please add discount");
                            }
                          } else {
                            console.log("second");
                            setQtyValue((valueQty) => valueQty + 1);
                            dispatch(addCardItemsAction(item.id, vel, "add"));
                          }
                        }}
                        className={
                          gridid == 2
                            ? "grid-button btn btn-outline-primary js-btn-plus"
                            : "btn btn-outline-primary js-btn-plus "
                        }
                        type="button"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryDetailitem;
