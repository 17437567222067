import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeAction,
  getCardItemsAction,
  getCustomerAction,
  getCustomerAddAction,
} from "../../redux/actions/productAction";
import HomeItem from "./Components/HomeItem";
import Carousel from "./Components/Carousel";
import CustomerModal from "../CustomerModal/CustomerModal";

const Home = () => {
  const dispatch = useDispatch();
  const { homelist, homeloading, sliderList } = useSelector(
    (state) => state.productState
  );

  const { username } = useSelector((state) => state.authState);

  useEffect(() => {
    document.title = "Category | DYK ";
    dispatch(HomeAction());
    dispatch(getCardItemsAction());
    if (username?.admin == "1") {
      dispatch(getCustomerAction(username?.id));
      dispatch(getCustomerAddAction());
    }
  }, []);

  return (
    <>
      {homeloading ? (
        <></>
      ) : (
        <>
          <CustomerModal />
          {/* <Carousel sliderList={sliderList} /> */}
          <div className="container ">
            <div className="row">
              {homelist?.map((item) => (
                <HomeItem item={item} />
              ))}
              <Link to={"/sparepart"} className="col-6 col-md-3 my-3 ">
                <div
                  style={{ backgroundColor: "#8EAC50" }}
                  className="card d-flex align-items-center h-100"
                >
                  <h4
                    className="bottom-0 text-white "
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  >
                    Find Spare
                  </h4>
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
