import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { LoginVerifyAction } from "../../redux/actions/authActons";
import OtpInput from "react-otp-input";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoginVerify() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [Mobile, setMobile] = React.useState("");

  const { mobile, userToken } = useSelector((state) => state.authState);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  React.useEffect(() => {
    setMobile(mobile);
  }, [mobile]);

  React.useEffect(() => {
    if (userToken) {
      navigate("/");
    } else {
    }
  }, [userToken]);


  React.useEffect(() => {
    document.title = "Login | Vishram ";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Mobile && otp) {
      dispatch(LoginVerifyAction(Mobile, otp));
      setMobile("");
      setOtp("");
    }
  };
  return (
    <>
      <button className="btn btn-info m-2">
        <i
          class="fa-solid fa-rotate-right"
          onClick={(e) => window.location.reload(false)}
        ></i>
      </button>
      {/* Spinner Start */}
      {/* <div
        id="spinner"
        className={`${
          LoginInLoading ? "show" : "hide"
        }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
      {/* Spinner End */}
      <div
        className="d-flex align-items-center"
        style={{
          height: "100vh",
        }}
      >
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
              }}
            >
              {/* <img
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
              }}
              src="assets/img/logo-dark.png"
              alt=""
              height={"70px"}
              width={"200px"}
            /> */}

              {/* <Typography component="h1" variant="h5" mt={2}>
                Sign
              </Typography> */}

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <h5 className="m-0">Whatsapp No.</h5>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="mobile"
                  label="Your Whatsapp No."
                  name="mobile"
                  autoComplete="mobile"
                  autoFocus
                  value={Mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                <div className="mt-3">
                  <h5>OTP</h5>
                  {/* <MuiOtpInput
                    inputMode="numeric"
                    value={otp}
                    onChange={handleChange}
                  /> */}
                  <OtpInput
                    inputType="number"
                    inputStyle={{
                      width: "100%",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span className="px-2">-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <Button
                  className="bg-primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
                {/* <Grid container>
                <Grid item>
                  <Link to="/enquiry" variant="body2">
                    Don't have an account? Enquiry
                  </Link>
                </Grid>
              </Grid> */}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </>
  );
}
