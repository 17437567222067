import React from "react";
import ImageLoader from "./ImageLoarder";
import { useNavigate } from "react-router-dom";

const HomeItem = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div className="col-6 col-md-3 ">
      <div
        className="profile-card-2 shadow "
        onClick={() =>
          navigate("/categorydetail", { state: { id: item.categoryId } })
        }
      >
        <img src={item?.categoryProfileImage} alt="Image Description" />
        {/* <img
          src={item?.categoryProfileImage}
          className="img img-responsive img-fluid"
        /> */}
        <div style={{ fontSize: "1.2rem" }} className="profile-name">
          {item?.categoryTitle}
        </div>
      </div>
    </div>
  );
};

export default HomeItem;
