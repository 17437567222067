import React, { useState } from "react";
import "./Ledger.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import { LedgerAction } from "../../redux/actions/authActons";
import { Link, useNavigate } from "react-router-dom";
import { getLedgerAction } from "../../redux/actions/productAction";
import QueryBox from "./Components/QueryBox";
// import DetailModal from "./DetailModal";

const Ledger = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getledger } = useSelector((state) => state.productState);
  console.log("getledger", getledger);

  useEffect(() => {
    dispatch(getLedgerAction());

    document.title = "Detail | Ledger ";
  }, []);

  const [visible, setVisible] = useState(false);

  return (
    <>
      <QueryBox />
      {/* <DetailModal /> */}
      {getledger?.data?.length === 0 ||
      (Array.isArray(getledger?.data) && getledger?.data?.length === 0) ? (
        <>
          {(Array.isArray(getledger?.data) && getledger?.data?.length === 0) ||
          getledger?.data?.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "100vh",
              }}
            >
              <h4 className="my-auto">Ledger Not Found</h4>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="container my-5 px-1" style={{ fontFamily: "cairo" }}>
          <div className="position-relative ">
            <Link
              style={{
                position: "fixed",
                top: 90,
                right: 0,
                backgroundColor: "#008183",
              }}
              onClick={() => {
                window.scrollTo({
                  top: document.body.offsetHeight,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="btn btn-lg  btn-lg-square "
            >
              <i className="bi bi-arrow-down" style={{ color: "#fff" }} />
            </Link>
          </div>

          <div className="">
            <div>
              <h1>WEL COME</h1>
              <h5>MR. {getledger?.name}</h5>
            </div>
          </div>
          <div className="py-5 ">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#QueryBoxMoal"
              onClick={() => window.$("#QueryBoxMoal").modal("show")}
            >
              Query Box
            </button>
            <table class="table">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Debit</th>
                  <th scope="col">Credit</th>
                  <th scope="col">Balance</th>
                </tr>
              </thead>
              <tbody>
                {getledger?.data?.map((item) => (
                  <tr>
                    <th
                      onClick={() => {
                        if (item.date) {
                          navigate("/ledgerdetail", {
                            state: {
                              date: item.date,
                              challan: item.challan,
                              gst_bill: item.gst_bill,
                              lr: item.lr,
                              parcel_photo: item.parcel_photo,
                              way_bill: item.way_bill,
                              challan_no: item.challan_no,
                            },
                          });
                        }
                      }}
                      scope="row"
                      style={{
                        backgroundColor:
                          item.type == "sale_invoice"
                            ? "#fcb6b6"
                            : item.type === "payment"
                            ? "#bcfcc7"
                            : item.type === "receipt"
                            ? "#e6d8ad"
                            : "#fff",
                      }}
                    >{`${item?.date} (${item?.type})`}</th>
                    <td
                      onClick={() => {
                        if (item.date) {
                          navigate("/ledgerdetail", {
                            state: {
                              date: item.date,
                              challan: item.challan,
                              gst_bill: item.gst_bill,
                              lr: item.lr,
                              parcel_photo: item.parcel_photo,
                              way_bill: item.way_bill,
                              challan_no: item.challan_no,
                            },
                          });
                        }
                      }}
                      style={{
                        backgroundColor:
                          item.type == "sale_invoice"
                            ? "#fcb6b6"
                            : item.type === "payment"
                            ? "#bcfcc7"
                            : item.type === "receipt"
                            ? "#e6d8ad"
                            : "#fff",
                      }}
                    >
                      {item?.debit}
                    </td>
                    <td
                      onClick={() => {
                        if (item.date) {
                          navigate("/ledgerdetail", {
                            state: {
                              date: item.date,
                              challan: item.challan,
                              gst_bill: item.gst_bill,
                              lr: item.lr,
                              parcel_photo: item.parcel_photo,
                              way_bill: item.way_bill,
                              challan_no: item.challan_no,
                            },
                          });
                        }
                      }}
                      style={{
                        backgroundColor:
                          item.type == "sale_invoice"
                            ? "#fcb6b6"
                            : item.type === "payment"
                            ? "#bcfcc7"
                            : item.type === "receipt"
                            ? "#e6d8ad"
                            : "#fff",
                      }}
                    >
                      {item?.credit}
                    </td>
                    <td
                      onClick={() => {
                        if (item.date) {
                          navigate("/ledgerdetail", {
                            state: {
                              date: item.date,
                              challan: item.challan,
                              gst_bill: item.gst_bill,
                              lr: item.lr,
                              parcel_photo: item.parcel_photo,
                              way_bill: item.way_bill,
                              challan_no: item.challan_no,
                            },
                          });
                        }
                      }}
                      style={{
                        backgroundColor:
                          item.type == "sale_invoice"
                            ? "#fcb6b6"
                            : item.type === "payment"
                            ? "#bcfcc7"
                            : item.type === "receipt"
                            ? "#e6d8ad"
                            : "#fff",
                      }}
                    >
                      {item?.balance}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <div className="border rounded m-2 fixed-bottom bg-light">
              <div className="d-flex">
                <h5 className="m-0 bg-info p-2 rounded-start">
                  Closing Balance
                </h5>
                <h5 className="m-0 p-2 ">{getledger?.finalbalance}</h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ledger;
