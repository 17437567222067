import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addCardItemsAction,
  addRemoveLikeItemsAction,
  removeCardItemAction,
} from "../../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Lightbox from "react-image-lightbox";

const SparePartDetailItem = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [qtyValue, setQtyValue] = useState(0);
  const { likeListIds, cartqty, gridid, getdiscount } = useSelector(
    (state) => state.productState
  );
  const { username } = useSelector((state) => state.authState);

  const addToCardItemFun = () => {
    dispatch(
      addCardItemsAction(item.id, qtyValue ? qtyValue.toString() : 1, "add")
    );
  };

  const LikeToUnLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "unlike"));
  };

  const UnLikeToLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "like"));
  };

  const hardIconComponent = likeListIds.find(
    (likeitemid) => likeitemid === item.id
  ) ? (
    <div>
      <i
        className="fa-solid fa-heart fs-4 me-3"
        style={{
          color: "#e84040",
          position: "absolute",
          top: "10px",
          right: "0px",
        }}
        onClick={() => LikeToUnLikeFun()}
      ></i>
    </div>
  ) : (
    <div>
      <i
        className="fa-solid fa-heart fs-4 me-3"
        style={{
          color: "grey",
          position: "absolute",
          top: "10px",
          right: "0px",
        }}
        onClick={() => UnLikeToLikeFun()}
      ></i>
    </div>
  );

  function filterField(event) {
    if (username?.admin == 1) {
      if (getdiscount > 0) {
        setQtyValue(Number(event.target?.value));
      } else {
        toast.error("Please add discount");
      }
    } else {
      setQtyValue(Number(event.target?.value));
    }
  }

  let vel = 1;

  useEffect(() => {
    setQtyValue(
      parseFloat(
        item.quantity
          ? item.quantity
          : item.quantity == null
          ? 0
          : item.quantity
      )
    );
  }, [item]);

  window.addEventListener("beforeunload", (event) => {
    event.preventDefault();
    event.returnValue = "";
  });

  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  let wordsArray = item?.productSKUCode?.split(" ");

  console.log(getdiscount);

  return (
    <>
      <div
        className={
          gridid == 2
            ? "col-lg-2 col-6 col-md-3 "
            : "col-6 col-md-3 col-lg-2 col-sm-4"
        }
      >
        <div
          className={
            gridid == 2 ? "d-flex" : " shadow rounded overflow-hidden h-100 "
          }
          style={{ backgroundColor: "aliceblue" }}
        >
          <div className="position-relative ">
            {gridid == 1 ? <>{hardIconComponent}</> : null}{" "}
            <img
              onClick={() => setIsOpen(true)}
              className="img-fluid"
              src={item.productImageThumb}
              alt=""
              style={{
                width: gridid == 2 ? "80px" : "180px",
                // flex: 1,
                height: gridid == 2 ? "80px" : "180px",
              }}
            />
          </div>

          {isOpen && item?.productImageThumb && (
            <Lightbox
              mainSrc={item?.productImageThumb}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}

          <div className={gridid == 2 ? "about-product-grid" : "about-product"}>
            <div className={gridid == 2 ? "mb-0" : "mb-2"}>
              <h5 className="mb-0 product-title">{item?.productTitle}</h5>
              <h5 className="mb-0">{item?.productSubTitle}</h5>
              <h5 className="mb-0">
                {wordsArray[0]}
                &nbsp;&nbsp;
                <span className="fw-bold text-primary">{wordsArray[1]}</span>
              </h5>
              <h5 className="mb-0">{item?.productTitle}</h5>
              <h5>{item?.containerUnit}Pieces/Box</h5>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="fw-bold text-bg-danger">INR {item?.price}</h5>
                <h5 className="me-1">
                  Pcs: {Number(item?.containerUnit) * qtyValue}
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between ">
              <div className="d-flex align-items-center justify-content-around">
                <div
                  className="input-group d-flex"
                  style={{ maxWidth: "120px" }}
                >
                  <div className="input-group-prepend">
                    <button
                      onClick={() => {
                        if (qtyValue == 1) {
                          setQtyValue(0);
                          dispatch(removeCardItemAction(item.id));
                        } else {
                          if (qtyValue > 0) {
                            dispatch(
                              addCardItemsAction(
                                item.id,
                                qtyValue - vel,
                                "update"
                              )
                            );
                            setQtyValue((valueQty) => valueQty - 1);
                          }
                        }
                      }}
                      className={
                        gridid == 2
                          ? " grid-button btn btn-outline-primary js-btn-minus"
                          : "btn btn-outline-primary js-btn-minus"
                      }
                      type="button"
                    >
                      &minus;
                    </button>
                  </div>
                  <input
                    // inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }} //add this
                    style={{ backgroundColor: qtyValue > 0 ? "	#FAA0A0" : "" }}
                    type="number"
                    inputMode="numeric"
                    className={
                      gridid == 2
                        ? "grid-button form-control text-center"
                        : "form-control text-center "
                    }
                    value={qtyValue}
                    placeholder=""
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                    onChange={(e) => {
                      filterField(e);
                    }}
                    onFocus={() => {
                      if (qtyValue == 0) setQtyValue("");
                    }}
                    onBlur={() => {
                      if (qtyValue == 0) {
                        setQtyValue(0);
                      }

                      if (username?.admin == 1) {
                        if (getdiscount != 0) {
                          dispatch(
                            addCardItemsAction(
                              item.id,
                              qtyValue ? qtyValue : 1,
                              "update"
                            )
                          );
                        } else {
                          toast.error("Please add discount");
                          setQtyValue(0);
                        }
                      } else {
                        dispatch(
                          addCardItemsAction(
                            item.id,
                            qtyValue ? qtyValue : 1,
                            "update"
                          )
                        );
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => {
                        if (username?.admin == 1) {
                          if (getdiscount != 0) {
                            setQtyValue((valueQty) => valueQty + 1);
                            dispatch(addCardItemsAction(item.id, vel, "add"));
                          } else {
                            toast.error("Please add discount");
                          }
                        } else {
                          setQtyValue((valueQty) => valueQty + 1);
                          dispatch(addCardItemsAction(item.id, vel, "add"));
                        }
                      }}
                      className={
                        gridid == 2
                          ? "grid-button btn btn-outline-primary js-btn-plus"
                          : "btn btn-outline-primary js-btn-plus "
                      }
                      type="button"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SparePartDetailItem;
