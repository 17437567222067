import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardItemsAction,
  getWishlistAction,
} from "../../redux/actions/productAction";
import WishListItem from "./Components/WishlistItem";

const Wishlist = () => {
  const dispatch = useDispatch();
  const { likeItems } = useSelector((state) => state.productState);

  useEffect(() => {
    dispatch(getWishlistAction());
    document.title = "Wishlist | DYK ";
  }, []);

  return (
    <>
      {Array.isArray(likeItems) && likeItems.length === 0 ? (
        <div
          className=" d-flex justify-content-center "
          style={{
            height: "100vh",
          }}
        >
          <h4 className="my-auto text-dark">No Wishlist Found</h4>
        </div>
      ) : (
        <div className="container  my-5">
          <div className="row ">
            {likeItems?.map((item) => (
              <React.Fragment key={item.id}>
                <WishListItem item={item} />
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Wishlist;
