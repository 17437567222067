import {
  LOG_OUT,
  LOGIN_VERIFY,
  LOGIN,
  LOADING,
  LOADING_ERROR,
} from "../actions/types";

const initialState = {
  userToken: null,
  mobile: null,
  ledgerlist: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_OUT:
      return {
        ...state,
        userToken: null,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LOGIN:
      return {
        ...state,
        mobile: action.payload,
        loading: false,
      };
    case LOGIN_VERIFY:
      return {
        ...state,
        userToken: action.payload,
        username: action.userpayload,
        loading: false,
      };

    default:
      return state;
  }
};
