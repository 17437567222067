import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CategoryDetailAction,
  getSparePartsAction,
  getWishlistAction,
} from "../../redux/actions/productAction";
import "./SparePart.css";
import CategoryDetailitem from "./Component/SparePartItem";
import SparePartItem from "./Component/SparePartItem";

const SparePart = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { sparepartlist, homeloading } = useSelector(
    (state) => state.productState
  );

  useEffect(() => {
    dispatch(getSparePartsAction());
    document.title = "Spare Part | DYK ";
  }, []);

  return (
    <>
      {homeloading ? null : (
        <div className="container my-5">
          <div className="row g-3">
            {sparepartlist?.map((item) => (
              <SparePartItem item={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SparePart;
