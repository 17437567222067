import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CategoryDetailAction,
  getSparePartsDetailAction,
  getWishlistAction,
} from "../../redux/actions/productAction";
import "./SparePartDetail.css";
import CategoryDetailitem from "./Component/SparePartDetailItem";
import SparePartDetailItem from "./Component/SparePartDetailItem";
import CustomerModal from "../CustomerModal/CustomerModal";

const SparePartDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sparepartdetail, homeloading } = useSelector(
    (state) => state.productState
  );

  useEffect(() => {
    dispatch(getSparePartsDetailAction(location.state.id));
    document.title = "Spare Part | DYK ";
  }, [location]);

  return (
    <>
      <div className="container my-5">
        <CustomerModal />
        <div className="row g-3">
          {sparepartdetail?.map((item) => (
            <SparePartDetailItem item={item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SparePartDetail;
