import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

const SparePartItem = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { gridid } = useSelector((state) => state.productState);

  console.log(gridid);
  return (
    <>
      <div
        className={
          gridid == 2 ? "col-lg-2 col-6 col-md-3 " : "col-6 col-md-4 col-lg-2"
        }
      >
        <div
          className={
            gridid == 2
              ? "d-flex shadow "
              : " shadow rounded overflow-hidden h-100 "
          }
          style={{ backgroundColor: "aliceblue" }}
        >
          <div className="position-relative ">
            <img
              className="img-fluid"
              src={item.productImageThumb}
              alt=""
              style={{
                // width: "auto",
                width: gridid == 2 ? "80px" : "180px",
                // flex: 1,
                height: gridid == 2 ? "80px" : "180px",
              }}
            />
            <button
              style={{ display: gridid == 2 ? "none" : "" }}
              onClick={() =>
                navigate("/sparepartdetail", {
                  state: {
                    id: item.productId,
                    diagram: item.sparePartDiagram,
                  },
                })
              }
              className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4 btn btn-primary"
            >
              View
            </button>
          </div>

          <div className={gridid == 2 ? "about-product-grid" : "about-product"}>
            <div className="mb-2">
              <h5 className="mb-0 product-title">{item?.productTitle}</h5>
              <h5 className="mb-0 product-title">{item?.productSKUCode}</h5>
              <button
                style={{ display: gridid == 1 ? "none" : "", height: "25px" }}
                onClick={() =>
                  navigate("/sparepartdetail", {
                    state: {
                      id: item.productId,
                      diagram: item.sparePartDiagram,
                    },
                  })
                }
                className=" bg-primary text-white rounded  btn btn-primary grid-button"
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SparePartItem;
