import React from "react";
import "./Lorder.css";
import { useSelector } from "react-redux";

const Lorder = () => {
  const { loading } = useSelector((state) => state.authState);

  return (
    <div
      id="spinner"
      style={{ zIndex: 2 }}
      className={` bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
    >
      <div
        className="spinner-border text-primary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      ></div>
    </div>
  );
};

export default Lorder;
