import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addRemoveLikeItemsAction,
  getWishlistAction,
  removeCardItemAction,
  removeWishListAction,
} from "../../../redux/actions/productAction";

const WishListItem = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [qtyValue, setQtyValue] = useState(1);

  useEffect(() => {
    setQtyValue(parseFloat(item.quantity));
  }, [item]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const LikeUnlikeItemFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "unlike"));
  };

  return (
    <div className=" col-md-4 mt-4" data-wow-delay="0.1s">
      <div className="room-item shadow rounded overflow-hidden d-flex h-100  bg-white">
        <div className="position-relative">
          <i
            style={{ position: "absolute", top: "5px", left: "5px" }}
            className="fa-solid fa-heart text-danger fs-3 "
            onClick={() => LikeUnlikeItemFun()}
          ></i>
          <img
            className="img-fluid"
            src={item.productImage}
            alt=""
            style={{
              height: "200px",
              width: "auto",
            }}
          />

          {/* <Link
            state={{ item: item }}
            to={{ pathname: "/productdetail" }}
            style={{ margin: "20px auto", left: "5px" }}
            className="position-absolute top-20 translate-middle-y bg-primary text-white rounded py-1 px-3"
          >
            View
          </Link> */}
        </div>
        <div className="p-2 col-md-6">
          <div className="d-flex justify-content-between mb-2">
            <h5 className="mb-0 fw-bold card-text-title">
              {item?.productTitle}
            </h5>
          </div>
          <div>
            <p className="m-0 text-dark mb-1 card-text">
              {item.productSubTitle}
            </p>
            <p className="m-0 text-dark mb-1 card-text">
              {item.productSKUCode}
            </p>
          </div>
          <div className="d-flex ">
            <p className="border-end me-2 pe-2 card-text">RS: {item?.price}</p>
            <p className=" me-2 pe-2 card-text">
              Pieces/Box: {item.containerUnit}
            </p>
            {/* <small className="border-end me-3 pe-3">{item.gr}</small> */}
          </div>
          <button
            onClick={() =>
              navigate("/productdetail", { state: { item: item } })
            }
            className="btn btn-primary mt-1"
          >
            View
          </button>
        </div>
      </div>

      {/* <Link
        style={{ display: visible ? " " : "none" }}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className="btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </Link> */}
    </div>
  );
};

export default WishListItem;
