import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogOutAction } from "../../redux/actions/authActons";
import "./Header.css";
import { GET_DISCOUNT, GRID } from "../../redux/actions/types";
import {
  CategoryDetailAction,
  DiscountUpdatAction,
  getSparePartsDetailAction,
} from "../../redux/actions/productAction";
import CustomerModal from "../CustomerModal/CustomerModal";
import { useEffect } from "react";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  window.$(document).click(function (event) {
    var clickover = window.$(event.target);
    var $navbar = window.$(".navbar-collapse");
    var _opened = $navbar.hasClass("in");
    if (_opened === true && !clickover.hasClass("navbar-toggle")) {
      $navbar.collapse("hide");
    }
  });

  const [discount, setDiscount] = useState("");

  const handleEntailmentRequest = (e) => {
    // e.preventDefault();
    window.location.reload(false);
    //do something...
  };

  const {
    cardTotalQty,
    gridid,
    selectedcustomer,
    getdiscount,
    totalcartprice,
  } = useSelector((state) => state.productState);
  const { username } = useSelector((state) => state.authState);

  useEffect(() => {
    setDiscount(getdiscount);
  }, [getdiscount]);

  useEffect(() => {
    if (gridid == undefined) {
      dispatch({ type: GRID, payload: 1 });
    }
  }, []);

  const onDiscountChange = (e) => {
    if (e.target.value > 100 || e.target.value < 0) {
    } else {
      if (!selectedcustomer) {
        console.log("customer not select");
        window.$("#CustomerModal").modal("show");
      } else {
        setDiscount(e.target.value);
        dispatch({
          type: GET_DISCOUNT,
          payload: Number(e.target.value),
        });
      }
    }

    dispatch(
      DiscountUpdatAction(
        selectedcustomer?.value,
        username.id,
        location.state.id,
        e.target.value,
        location
      )
    );
  };

  return (
    <div className="sticky-top" style={{ zIndex: 1000 }}>
      <nav class="navbar navbar-expand-lg bg-body-tertiary ">
        <div class="container-fluid">
          {/* <a class="navbar-brand logo" href="#">
            DYK
          </a> */}
          <img
            src="Assets/img/logo.png"
            alt=""
            style={{ height: 40, width: "auto", marginRight: 10 }}
          />
          {/* <button className="btn btn-info m-2">
          <i
            class="fa-solid fa-rotate-right"
            onClick={(e) => window.location.reload(false)}
          ></i>
        </button> */}

          <div className="d-flex align-items-center">
            <div className="" style={{ width: "80px" }}>
              {username?.admin == 1 ? (
                <p
                  className=" me-2 fw-bold d-block d-lg-none"
                  style={{ fontSize: "10px", width: "70px" }}
                >
                  {selectedcustomer?.label}
                </p>
              ) : null}
              <p
                className=" me-2 fw-bold d-block d-lg-none"
                style={{ fontSize: "12px", width: "130px" }}
              >
                Total: {totalcartprice}
              </p>
            </div>

            <div className="text-center d-block d-lg-none ">
              <Link to="/cart" class=" position-relative me-3">
                <i class="fa-solid fa-cart-shopping fs-4 "></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cardTotalQty ? cardTotalQty : 0}
                  <span class="visually-hidden">unread messages</span>
                </span>
              </Link>

              <Link to="/wishlist">
                <i class="fa-solid fa-heart fs-4  me-3"></i>
              </Link>
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>

          <div class="collapse navbar-collapse in" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link
                  to={"/"}
                  className={` nav-link ${
                    location.pathname === "/" ? "active ps-3 ps-md-2" : ""
                  } `}
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              {/* {selectedcustomer?.value ? (
                <li class="nav-item">
                  <Link
                    to={"/customeradd"}
                    className={` nav-link ${
                      location.pathname === "/customeradd"
                        ? "active ps-3 ps-md-2"
                        : ""
                    } `}
                    aria-current="page"
                  >
                    Customer Add
                  </Link>
                </li>
              ) : null} */}

              {/*  <li class="nav-item">
              <Link
                to={"/pendingorder"}
                className={` nav-link ${
                  location.pathname === "/pendingorder"
                    ? "active ps-3 ps-md-2"
                    : ""
                } `}
                aria-current="page"
              >
                Pending Order
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to={"/sparepart"}
                className={` nav-link ${
                  location.pathname === "/sparepart"
                    ? "active ps-3 ps-md-2"
                    : ""
                } `}
                aria-current="page"
              >
                Spare Part
              </Link>
            </li> */}
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  onClick={() => {
                    if (window.confirm("Are You Sure?")) {
                      dispatch(LogOutAction());
                    }
                  }}
                >
                  Log Out
                </a>
              </li>
            </ul>
            {username?.admin == 1 ? (
              <div className="h5 me-2 fw-bold d-block d-none d-md-block">
                {selectedcustomer?.label}
              </div>
            ) : null}
            <div className="h5 me-2 fw-bold d-block d-none d-md-block">
              {totalcartprice}
            </div>

            {console.log(totalcartprice)}

            <div className=" d-block d-none d-md-block">
              <Link to="/cart" class=" position-relative me-3">
                <i class="fa-solid fa-cart-shopping fs-4 "></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cardTotalQty ? cardTotalQty : 0}
                  <span class="visually-hidden">unread messages</span>
                </span>
              </Link>

              <Link to="/wishlist">
                <i class="fa-solid fa-heart fs-4  me-3"></i>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <>
        {pathname === "/" ? null : (
          <>
            <div className="h-5 bg-white py-2  text-center  ">
              <div
                onClick={() => navigate(-1)}
                className="d-flex align-items-center"
                style={{ position: "absolute", left: "10px" }}
              >
                <i
                  className="fa-solid fa-chevron-left fs-5"
                  style={{ color: "#000" }}
                ></i>
                <h5>BACK</h5>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <h5>{pathname.slice(1).toUpperCase()}</h5>

                {location.pathname == "/categorydetail" ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <div
                          onClick={() => dispatch({ type: GRID, payload: 1 })}
                          style={{
                            backgroundColor: gridid == 1 ? "#dc3545" : "",
                            marginRight: "10px",
                            padding: "5px",
                          }}
                        >
                          <img
                            src="Assets/img/grid1.png"
                            alt=""
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </div>
                        <div
                          onClick={() => dispatch({ type: GRID, payload: 2 })}
                          style={{
                            backgroundColor: gridid == 2 ? "#dc3545" : "",
                            marginRight: "10px",
                            padding: "5px",
                          }}
                        >
                          <img
                            src="Assets/img/grid2.png"
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {location.pathname == "/sparepart" ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <div
                          onClick={() => dispatch({ type: GRID, payload: 1 })}
                          style={{
                            backgroundColor: gridid == 1 ? "#dc3545" : "",
                            marginRight: "10px",
                            padding: "5px",
                          }}
                        >
                          <img
                            src="Assets/img/grid1.png"
                            alt=""
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </div>
                        <div
                          onClick={() => dispatch({ type: GRID, payload: 2 })}
                          style={{
                            backgroundColor: gridid == 2 ? "#dc3545" : "",
                            marginRight: "10px",
                            padding: "5px",
                          }}
                        >
                          <img
                            src="Assets/img/grid2.png"
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {location.pathname == "/sparepartdetail" ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <div
                          onClick={() => dispatch({ type: GRID, payload: 1 })}
                          style={{
                            backgroundColor: gridid == 1 ? "#dc3545" : "",
                            marginRight: "10px",
                            padding: "5px",
                          }}
                        >
                          <img
                            src="Assets/img/grid1.png"
                            alt=""
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </div>
                        <div
                          onClick={() => dispatch({ type: GRID, payload: 2 })}
                          style={{
                            backgroundColor: gridid == 2 ? "#dc3545" : "",
                            marginRight: "10px",
                            padding: "5px",
                          }}
                        >
                          <img
                            src="Assets/img/grid2.png"
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {username?.admin == 1 ? (
                  location.pathname === "/order" ? (
                    <>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#CustomerModal"
                        style={{ position: "absolute", right: "10px" }}
                        onClick={() => window.$("#CustomerModal").modal("show")}
                      >
                        Select Customer
                      </button>
                    </>
                  ) : null
                ) : null}

                {location?.state?.diagram ? (
                  <button
                    onClick={() =>
                      navigate("/diagram", {
                        state: { url: location?.state?.diagram },
                      })
                    }
                    className="btn btn-primary "
                    style={{ position: "absolute", right: "10px" }}
                  >
                    Diagram
                  </button>
                ) : null}
              </div>
            </div>
          </>
        )}
      </>
      {/* {username?.admin == 1 ? ( */}
      <>
        {location.pathname == "/categorydetail" ? (
          <div className="bg-light px-2 ">
            <div className="d-flex align-items-center col-lg-2 col-md-3 col-sm-4 col-6 ">
              <h5 className="me-2">Discount</h5>
              <input
                type="number"
                value={discount}
                onChange={(e) => onDiscountChange(e)}
                className="form-control "
              />
            </div>
          </div>
        ) : null}
        {location.pathname == "/sparepartdetail" ? (
          <div className="bg-light px-2 ">
            <div className="d-flex align-items-center col-lg-2 col-md-3 col-sm-4 col-6 ">
              <h5 className="me-2">Discount</h5>
              <input
                type="number"
                value={discount}
                onChange={(e) => onDiscountChange(e)}
                className="form-control "
              />
            </div>
          </div>
        ) : null}
      </>
      {/* ) : null} */}
    </div>
  );
};

export default Header;
