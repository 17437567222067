import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  CustomerAddAction,
  getCustomerAddAction,
} from "../../redux/actions/productAction";

const AddAdmin = () => {
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);

  const { customeraddloading, getcustomeradd } = useSelector(
    (state) => state.productState
  );

  useEffect(() => {
    dispatch(getCustomerAddAction());
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const mobile = data.get("mobile");

    if (mobile) {
      dispatch(
        CustomerAddAction(
          data.get("name"),
          data.get("email"),
          data.get("mobile"),
          selectedOption?.value
        )
      );
      event.target.reset();
    } else {
      toast.error("mobile no. is required");
    }
  };

  console.log(selectedOption, "selectedOption");
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "75vh" }}
    >
      <div className="row ">
        <div className="col-md-12 bg-white text-center p-4 shadow rounded ">
          <h5 className="fw-bold fs-4">Customer Add</h5>
          <Box
            className="mt-4"
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl variant="standard">
              <div className="d-flex">
                <Input
                  style={{ marginRight: 10 }}
                  placeholder="Name"
                  type="text"
                  name="name"
                  autoComplete="name"
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <i className="fa-solid fa-user text-dark"></i>
                    </InputAdornment>
                  }
                />
                <Input
                  placeholder="Email"
                  type="text"
                  name="email"
                  autoComplete="email"
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <i class="fa-solid fa-envelope text-dark"></i>
                    </InputAdornment>
                  }
                />
              </div>
            </FormControl>

            <div className="mt-4 d-flex">
              <Input
                style={{ marginRight: 10, flex: 1 }}
                placeholder="Mobile No."
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }} //add this
                type="number"
                inputMode="numeric"
                name="mobile"
                autoComplete="mobile"
                id="input-with-icon-adornment"
                startAdornment={
                  <InputAdornment position="start">
                    <i class="fa-solid fa-phone text-dark"></i>
                  </InputAdornment>
                }
              />
              <div style={{ flex: 1 }}>
                <Select
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      borderInline: "none",
                      borderTop: "none",
                      borderRadius: "none",
                      borderBlockColor: !state.isFocused ? "grey" : "grey",
                      "&:hover": {
                        border: "2px solid black",
                        // boxShadow: "0px 0px 6px #ff8b67",
                        borderInline: "none",
                        borderTop: "none",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "red",
                    }),
                  }}
                  // className="border-bottom border-dark "
                  defaultValue={{ value: 0, label: "-- SELECT --" }}
                  onChange={setSelectedOption}
                  options={getcustomeradd}
                />
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <LoadingButton
                type="submit"
                // onClick={(e) => handleSubmit(e)}
                endIcon={<i class="fa-regular fa-paper-plane"></i>}
                loading={customeraddloading}
                loadingPosition="end"
                variant="contained"
                color="error"
              >
                <span>Submit</span>
              </LoadingButton>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
