import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addCardItemsAction,
  removeCardItemAction,
} from "../../../redux/actions/productAction";
import "./CartItem.css";

const CartItem = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [qtyValue, setQtyValue] = useState(1);

  useEffect(() => {
    setQtyValue(parseFloat(item.quantity));
  }, [item]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  function filterField(event) {
    setQtyValue(parseFloat(event.target.value));
  }
  return (
    <div
      className="col-6 col-sm-4 col-md-3 col-lg-2 mt-4"
      data-wow-delay="0.1s"
    >
      <div className="room-item shadow rounded overflow-hidden  bg-white h-100">
        <div className="position-relative">
          <button
            onClick={() =>
              navigate("/productdetail", {
                state: { item: item, qty: qtyValue },
              })
            }
            className="btn btn-primary position-absolute"
            style={{ top: "10px", left: "10px" }}
          >
            view
          </button>
          <img
            className="img-fluid"
            src={item.productImage}
            alt=""
            style={{
              height: "200px",
              width: "auto",
            }}
          />
        </div>
        <div className="p-2 col-md-12">
          <div className="d-flex justify-content-between mb-2">
            <h5 className="mb-0 fw-bold card-text-title">
              {item?.productTitle}
            </h5>
            <i
              className="fa-solid fa-trash-can text-danger fs-3 "
              onClick={() => dispatch(removeCardItemAction(item.id))}
            ></i>
          </div>
          <div>
            <p className="m-0 text-dark mb-1 card-text">
              {item.productSubTitle}
            </p>
            <p className="m-0 text-dark mb-1 card-text">
              {item.productSKUCode}
            </p>
          </div>

          <div className="d-flex ">
            <p className="border-end me-2 pe-2 card-text  text-bg-danger">
              RS: {item?.price}
            </p>
            <p className=" me-2 pe-2 card-text">
              Pieces/Box: {item.containerUnit}
            </p>
          </div>
          <div className="d-flex ">
            <p className="border-end me-2 pe-1 card-text">
              Total Rs:
              <span className="fw-bold">{item?.item_price}</span>
            </p>
            <p className=" me-2 pe-0 card-text">
              Total Pcs:
              <span className="fw-bold">{parseFloat(item.pieces)}</span>
            </p>
          </div>

          <div className="d-flex align-items-center mt-3 justify-content-between ">
            <div className="input-group d-flex" style={{ width: "120px" }}>
              <div className="input-group-prepend">
                <button
                  onClick={() => {
                    if (qtyValue > 1) {
                      dispatch(
                        addCardItemsAction(
                          item.id,
                          parseFloat(item.quantity) - 1,
                          "update"
                        )
                      );
                      setQtyValue((valueQty) => valueQty - 1);
                    }
                  }}
                  className="btn btn-outline-primary js-btn-minus"
                  type="button"
                >
                  &minus;
                </button>
              </div>
              {/* <input
                type="number"
                min={1}
                className="form-control text-center qty"
                value={qtyValue}
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                onChange={(e) => {
                  setQtyValue(e.target.value);
                }}
              /> */}
              <input
                type="number"
                min={1}
                className="form-control text-center qty"
                value={qtyValue}
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                onChange={(e) => {
                  filterField(e);
                }}
                onBlur={() => {
                  dispatch(
                    addCardItemsAction(
                      item.id,
                      qtyValue ? qtyValue.toString() : 1,
                      "update"
                    )
                  );
                }}
              />
              <div className="input-group-append">
                <button
                  onClick={() => {
                    dispatch(
                      addCardItemsAction(
                        item.id,
                        parseFloat(item.quantity) + 1,
                        "update"
                      )
                    );
                    setQtyValue((valueQty) => valueQty + 1);
                  }}
                  className="btn btn-outline-primary js-btn-plus"
                  type="button"
                >
                  +
                </button>
              </div>
            </div>

            {/* <div
              style={{ width: "40%" }}
              className="me-2"
              data-bs-toggle="modal"
              data-bs-target="#remarkmodal"
            >
              <p className=" border-bottom m-0">
                {item.remark && item.remark.length > 15
                  ? `${item.remark.substring(0, 15)}...`
                  : item.remark
                  ? item.remark
                  : "write remark"}
              </p>
            </div> */}
          </div>
        </div>
      </div>

      {/* <Link
        style={{ display: visible ? " " : "none" }}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className="btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </Link> */}
    </div>
    // <section class="h-100" style={{ backgroundColor: "#eee" }}>
    //   <div class="container h-100">
    //     <div class="row d-flex justify-content-center align-items-center h-100">
    //       <div class="col-12">
    //         <div class="card rounded-3 ">
    //           <div class="card-body p-4">
    //             <div class="row d-flex justify-content-between align-items-center">
    //               <div class="col-2 col-md-2 col-lg-2 col-xl-2">
    //                 <img
    //                   src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img1.webp"
    //                   class="img-fluid rounded-3"
    //                   alt="Cotton T-shirt"
    //                 />
    //               </div>
    //               <div class="col-2 col-md-3 col-lg-3 col-xl-3">
    //                 <p class="lead fw-normal mb-2 fs-6">Basic T-shirt</p>
    //               </div>
    //               <div class="col-2 col-md-3 col-lg-3 col-xl-2 d-flex">
    //                 <button
    //                   class="btn btn-link px-2"
    //                   onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
    //                 >
    //                   <i class="fas fa-minus"></i>
    //                 </button>

    //                 <input
    //                   id="form1"
    //                   min="0"
    //                   name="quantity"
    //                   value="2"
    //                   type="number"
    //                   class="form-control form-control-sm"
    //                 />

    //                 <button
    //                   class="btn btn-link px-2"
    //                   onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
    //                 >
    //                   <i class="fas fa-plus"></i>
    //                 </button>
    //               </div>
    //               <div class="col-2 col-md-3 col-lg-2 col-xl-2 offset-lg-1">
    //                 <h5 class="mb-0">$499.00</h5>
    //               </div>
    //               <div class="col-2 col-md-1 col-lg-1 col-xl-1 text-end">
    //                 <a href="#!" class="text-danger">
    //                   <i class="fas fa-trash fa-lg"></i>
    //                 </a>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default CartItem;
