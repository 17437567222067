import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { placeOrderAction } from "../../../redux/actions/productAction";
import { useNavigate } from "react-router-dom";


const PlaceOrderModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cardTotalQty, totalprice } = useSelector(
    (state) => state.productState
  );
  const [remark, setRemark] = useState("");


  return (
    <div
      class="modal fade"
      id="placeordermodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Order Summary
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Total Quantity:</h5>
              <h5 className="fw-bold">{cardTotalQty}</h5>
            </div>
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Total Price:</h5>
              <h5 className="fw-bold">INR {totalprice}</h5>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Grand Total:</h5>
              <h5 className="fw-bold">INR {totalprice}</h5>
            </div>

            <div className="mt-2">
              <label className="fw-bold form-label h5" htmlFor="">
                Remark:
              </label>
              <textarea
                className="form-control "
                placeholder="Enter Remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                dispatch(placeOrderAction(remark, navigate));
                window.$("#placeordermodal").modal("hide");
              }}
            >
              Confirm Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderModal;
